import React, { useState, useEffect } from 'react';
import './MailchimpForm.css';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useCookies } from 'react-cookie';

import InputField from './InputField';

const CustomForm = ({ status, message, onValidated }) => {

  const [email, setEmail] = useState('');
  const [cookies, setCookie] = useCookies();

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email,
    });
  }

  useEffect(() => {
    if (status === 'success') {
      setCookie('userHasSubmittedEmail', true);
    }
  }, [status])

  const clearFields = () => {
    setEmail('');
  }

  return (
    <form
      className="mc__form"
      onSubmit={(e) => handleSubmit(e)}
      readOnly={true}
    >
      <h3 className="mc__title">
        {status === 'success' ? 'Thank you!' :
          'Join us to access the data'}
      </h3>

      {status === "sending" && (
        <div
          className="mc__alert mc__alert--sending"
        >sending...</div>
      )}
      {status === 'error' && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === 'success' && (
        <div
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {status !== "success" ? (
        <div className="mc__field-container">
          <InputField
            label=""
            onChangeHandler={setEmail}
            type="email"
            value={email}
            placeholder="your@email.com"
            isRequired
          />

          {/* For segmenting */}
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            {/* <input type="text" value="true" name="SOURCE" tabindex="-1" /> */}
            {/* <input type="hidden" name="tags" value="13269824" /> */}
            <input type="text" value="" name="SOURCE" class="" id="mce-SOURCE"></input>
          </div>
          

          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_c8b6ea012bb2faf0bb748f770_0385a23556" tabindex="-1" value="" /></div>
          {/* <div hidden={true}><input type="hidden" name="tags" value="449957" /></div> */}
        </div>
      ) : null}

      {/*Close button appears if form was successfully sent*/}
      {
        status === 'success' ? (
          // <div>Success</div>
          // <PrimaryCTAButton
          //   // handleClick={() => setModalOpen(false)}
          //   label="close"
          //   size="big"
          //   customClass="g__justify-self-center"
          // />
          <div></div>
        ) : <InputField
          label="join"
          type="submit"
          formValues={[email]}
        />

      }
    </form>
  );
};

function MailchimpFormContainer(props) {
  const postUrl = `https://i-mak.us10.list-manage.com/subscribe/post?u=c8b6ea012bb2faf0bb748f770&amp;id=0385a23556&amp;f_id=00e331e2f0&amp;tags=`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe({
              ...formData,
              DPB2022: [true],
            })}
          />
        )}
      />
    </div>

  );
};

export default MailchimpFormContainer;
