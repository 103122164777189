import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
// import { auth, database } from '../firebase';
// import { collection, getDocs } from 'firebase/firestore';
import { useCookies } from 'react-cookie';
import {
  formatDate,
  objectIsEmpty,
  resultsLength,
} from '../utils';
import MailchimpFormContainer from './MailchimpFormContainer';
import {
  IconChevronDown,
  IconChevronUp,
  IconDownload,
} from '../Icons';
import { SortableIcon } from './SortableIcon';
import DateRangeSelect from './DateRangeSelect';

const json = require('../data.json');

export default function Main() {
  const [cookies, setCookie] = useCookies();
  const [patentData, setPatentData] = useState();
  const [patentDataFiltered, setPatentDataFiltered] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({
    drug: [],
    assignee: [],
    assigneeArray: [],
    conditionsTreated: [],
    dateFiled: [],
    dateExpiry: [],
    patentType: [],
    status: [],
    statusFdaApproval: [],
    statusOPBook: [],
  });

  const [selectedFilters, setSelectedFilters] = useState({
    drug: [],
    assignee: [],
    assigneeArray: [],
    conditionsTreated: [],
    dateFiled: [],
    dateExpiry: [],
    patentType: [],
    status: [],
    statusFdaApproval: [],
    statusOPBook: [],
    patentNo: '',
  });

  const [didFetch, setDidFetch] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [sortBy, setSortBy] = useState(['drug', 'ASC']);

  // Dates
  const [filedDateRange, setFiledRangeDate] = useState([]);
  const [filedDateRangeOpen, setFiledDateRangeOpen] = useState(false);

  const [expiryDateRange, setExpiryRangeDate] = useState([]);
  const [expiryDateRangeOpen, setExpiryDateRangeOpen] = useState(false);

  let navigate = useNavigate();

  const { pathname } = window.location;

  // Get filters
  function getFilters(data) {
    const drug = data.map(data => data.drug)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(value => value !== undefined)
      .sort();

    const assignee = data.map(data => data.assignee)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(value => value)
      .sort();

    const assigneeArrayNew = [];
    data.map(item => {
      const set = assigneeArrayNew.push(...item.assigneeArray.values());
    })
    const assigneeArray = [...new Set(assigneeArrayNew)].sort();

    const conditionsTreated = data.map(data => data.conditionsTreated)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(value => value !== undefined)
      .sort();

    const patentType = data.map(data => data.patentType)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(value => value !== undefined)
      .sort();
    
    const status = data.map(data => data.status)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(value => value && value !== undefined)
      .sort();

    const statusFdaApproval = data.map(data => data.statusFdaApproval)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(value => value && value !== undefined)
      .sort();

    const statusOPBook = data.map(data => data.statusOPBook)
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(value => value !== undefined)
      .sort();

    setFilters({
      drug,
      assignee,
      assigneeArray,
      conditionsTreated,
      patentType,
      status,
      statusFdaApproval,
      statusOPBook,
    })
  }

  // Get patents from database from firestore
  // TODO remove
  // async function fetchData(auth, db) {
  //   const patentsCol = collection(db, 'patents');
  //   const patentsSnapshot = await getDocs(patentsCol);
  //   if (patentsSnapshot) {
  //     const patentsList = patentsSnapshot.docs.map(doc => doc.data());
  //     setPatentData(patentsList);
  //     getFilters(patentsList);
  //   }
  // }

  // Apply filters
  const filterData = (data, selectedFilters) => {
    const filterKeys = Object.keys(selectedFilters);
    // applyParams(selectedFilters);

    return data.filter(entry => {
      return filterKeys.every(key => {
        if (!selectedFilters[key].length) return true;
        // Loops again if entry[key] is an array (for material attribute).
        if (Array.isArray(entry[key])) {
          return entry[key].some(keyEle => selectedFilters[key].includes(keyEle));
        }
        if (key === 'patentNo') {
          return selectedFilters[key].includes(entry[key]) || entry[key].includes(selectedFilters[key])
        }
        if (key === 'dateExpiry') {
          const rangeStart = new Date(selectedFilters.dateExpiry[0]);
          const rangeEnd = new Date(selectedFilters.dateExpiry[1]);
          // const date = new Date(entry.dateStringExpiry);
          const date = new Date(entry.dateExpiry?.__time__);
          return rangeEnd > date && date > rangeStart;
        }
        if (key === 'dateFiled') {
          const rangeStart = new Date(selectedFilters.dateFiled[0]);
          const rangeEnd = new Date(selectedFilters.dateFiled[1]);
          // const date = new Date(entry.dateStringFile);
          const date = new Date(entry.dateFile?.__time__);
          return rangeEnd > date && date > rangeStart;
        }
        return selectedFilters[key].includes(entry[key]);
      });
    });
  };

  const clearFilters = () => {
    setSelectedFilters({
      drug: [],
      assignee: [],
      assigneeArray: [],
      conditionsTreated: [],
      dateFiled: [],
      dateExpiry: [],
      patentType: [],
      statusFdaApproval: [],
      statusOPBook: [],
    })
    setSearchParams('');
  }

  useEffect(() => {
    if (!didFetch) {
      setDidFetch(true);

      // fetch from firebase
      // fetchData(auth, database);

      // fetch from local json file
      const data = Object.values(json.data);
      setPatentData(data);
      getFilters(data);
    }

    if (patentData && selectedFilters && sortBy.length === 0) {
      const filteredResults = filterData(patentData, selectedFilters)
        .sort((a, b) => {
          return (a.drug < b.drug) ? -1 : (a.drug > b.drug) ? 1 : 0;
        });
      setPatentDataFiltered(filteredResults);
    }

    // Sorting
    if (patentData && sortBy.length > 0 && selectedFilters) {
      const key = sortBy[0];
      const dir = sortBy[1];

      let filteredResultsASC;
      let filteredResultsDESC;

      if (key === 'dateFile' || key === 'dateExpiry') {
        filteredResultsASC = filterData(patentData, selectedFilters)
          .sort((a, b) => {
            const itemA = (a[key] && a[key].__time__ && a[key].__time__.split('T')[0]) || '';
            const itemB = (b[key] && b[key].__time__ && b[key].__time__.split('T')[0]) || '';
            return (itemA < itemB) ? -1 : (itemA > itemB) ? 1 : 0;
          });
      
        filteredResultsDESC = filterData(patentData, selectedFilters)
          .sort((a, b) => {
            const itemA = (a[key] && a[key].__time__ && a[key].__time__.split('T')[0]) || '';
            const itemB = (b[key] && b[key].__time__ && b[key].__time__.split('T')[0]) || '';
            return (itemA < itemB) ? 1 : (itemA > itemB) ? -1 : 0;
          });
      } else {
        filteredResultsASC = filterData(patentData, selectedFilters)
          .sort((a, b) => {
            return (a[key] < b[key]) ? -1 : (a[key] > b[key]) ? 1 : 0;
          });
      
        filteredResultsDESC = filterData(patentData, selectedFilters)
          .sort((a, b) => {
            return (a[key] < b[key]) ? 1 : (a[key] > b[key]) ? -1 : 0;
          });
      }

      if (dir === 'ASC' && filteredResultsASC) {
        setPatentDataFiltered(filteredResultsASC);
      }
      if (dir === 'DESC' && filteredResultsDESC) {
        setPatentDataFiltered(filteredResultsDESC);
      }
    }

  }, [patentData, selectedFilters, sortBy, didFetch]);

  useEffect(() => {
    const drug = searchParams.get('drug') ? searchParams.get('drug') : '';

    if (drug && filters?.drug?.includes(drug)) {
      applyFilters('drug', drug)
    }

  }, [filters]);

  useEffect(() => {
    const disableOverlay = searchParams.get('disableOverlay') || '';

    if (disableOverlay) {
      setCookie('userHasSubmittedEmail', true);
    }
  });

  // function applyParams(selectedFilters) {
  //   if (selectedFilters) {
  //     console.log('selectedFilters-', selectedFilters)

  //     const queryString = Object.entries(selectedFilters)
  //       .map(([k, v]) => {
  //         if (Object.values(v).length > 0) {
  //           return `${k}=${Object.values(v)}`
  //         }
  //       })
  //       .join('&');

  //     // const params = createSearchParams(queryString);
  //     setSearchParams(queryString.toString());
  //     // history.push({ '/', search: queryString.toString() });
  //     // navigate(`/?${queryString}`)


  //     // if (selectedFilters.drug.length && selectedFilters.drug.length == 1) {
  //     //   const drugName = selectedFilters.drug[0];
  //     //   navigate(`/drug/${drugName}`);
  //     // }
  //     // else {
  //     //   navigate('/');
  //     // }
  //   }
  // }

  // Apply filters
  function applyFilters(filter, item) {
    let copiedSelectedFilters = { ...selectedFilters };

    if (copiedSelectedFilters[filter]?.includes(item)) {
      const idx = copiedSelectedFilters[filter].findIndex(elem => elem === item);

      setSelectedFilters(selectedFilters => ({
        ...selectedFilters,
        [filter]: [
          ...copiedSelectedFilters[filter].slice(0, idx),
          ...copiedSelectedFilters[filter].slice(idx + 1),
        ]
      }));

    } else {
      const string = [item];
      setSelectedFilters(selectedFilters => ({
        ...selectedFilters,
        [filter]: [
          ...copiedSelectedFilters[filter],
          ...string
        ]
      }));
    }
  }

  function applyPatentNoFilter(patent) {
    setSelectedFilters(selectedFilters => ({
      ...selectedFilters,
      patentNo: patent,
    }));
  }

  function applyFiltersDate(filter, item) {
    setSelectedFilters(selectedFilters => ({
      ...selectedFilters,
      [filter]: item,
    }));
  }

  function toggleRow(idx) {
    const index = expandedRows.findIndex((id) => id === idx);
    if (index !== -1) {
      setExpandedRows([
        ...expandedRows.slice(0, index),
        ...expandedRows.slice(index + 1),
      ]);
    } else {
      setExpandedRows([
        ...expandedRows,
        idx,
      ])
    }
  }

  const onDateChange = (ranges) => {
    const { id, startDate, endDate } = ranges;
    const startDateFormatted = formatDate(startDate);
    const endDateFormatted = formatDate(endDate);

    if (id === 'filed') {
      setFiledRangeDate([startDateFormatted, endDateFormatted]);
      applyFiltersDate('dateFiled', [startDateFormatted, endDateFormatted])

      if(startDateFormatted !== endDateFormatted) {
        setFiledDateRangeOpen(false);
      } else if (startDate === '' && endDate === '') {
        setFiledDateRangeOpen(false);
      }
    }
    if (id === 'expiry') {
      setExpiryRangeDate([startDateFormatted, endDateFormatted]);
      applyFiltersDate('dateExpiry', [startDateFormatted, endDateFormatted])

      if(startDateFormatted !== endDateFormatted) {
        setExpiryDateRangeOpen(false);
      } else if (startDate === '' && endDate === '') {
        setExpiryDateRangeOpen(false);
      }
    }
    return ranges;
  }

  const OPBookLabel = (bool) => {
    if (bool) return 'Yes';
    return 'No';
  }

  const dateFormatted = (date) => {
    if (date && date.__time__) return date.__time__.split('T')[0];
    return null;
  }

  return (
    <div className={`Main ${!cookies.userHasSubmittedEmail && 'subscribe-overlay'}`}>
      {!cookies.userHasSubmittedEmail && (
        <MailchimpFormContainer />
      )}

      <div className="container">
        <div className="filters-heading">
          <h3>Filter by:</h3>
          <button
            className={`btn btn-grey btn-clear ${objectIsEmpty(selectedFilters) && 'hidden'}`}
            onClick={() => clearFilters()}
          >
            Clear All Filters
          </button>
        </div>

        <div className="filters">
          <div className="row">
            <div className="col filter-group">
              <h4>Drug Name</h4>
              <div className="filter-group-inner">
                {filters?.drug?.map((filter, idx) => (
                  <div className="fieldset" key={`f-drug-${idx}`}>
                    <input
                      type="checkbox"
                      id={`drug-${filter}`}
                      name={`drug-${filter}`}
                      onChange={() => applyFilters('drug', filter)}
                      checked={selectedFilters.drug.includes(filter)}
                    />
                    <label htmlFor={`drug-${filter}`}>{filter}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="col filter-group">
              <h4>Condition(s) Treated</h4>
              <div className="filter-group-inner">
                {filters?.conditionsTreated?.map((filter, idx) => (
                  <div className="fieldset" key={`f-conditionsTreated-${idx}`}>
                    <input
                      type="checkbox"
                      id={`conditionsTreated-${filter}`}
                      name={`conditionsTreated-${filter}`}
                      onChange={() => applyFilters('conditionsTreated', filter)}
                      checked={selectedFilters.conditionsTreated.includes(filter)}
                    />
                    <label htmlFor={`conditionsTreated-${filter}`}>{filter}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="col filter-group">
              <h4>Assignee</h4>
              <div className="filter-group-inner">
                {filters?.assigneeArray?.map((filter, idx) => (
                  <div className="fieldset" key={`f-assignee-${idx}`}>
                    <input
                      type="checkbox"
                      id={`assignee-${filter}`}
                      name={`assignee-${filter}`}
                      onChange={() => applyFilters('assigneeArray', filter)}
                      checked={selectedFilters.assigneeArray.includes(filter)}
                    />
                    <label htmlFor={`assignee-${filter}`}>{filter}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="col filter-group">
              <h4>Patent/Publication Number</h4>
              <div className="filter-group-inner">
                <input
                  id="patentNoInput"
                  type="text"
                  name="patentNo"
                  value={(selectedFilters && selectedFilters.patentNo) || ''}
                  onChange={e => applyPatentNoFilter(e.target.value)}
                />
              </div>

              <h4 className="filter-heading date">
                {setFiledDateRangeOpen && (
                  <DateRangeSelect
                    id="filed"
                    onChange={onDateChange}
                    open={filedDateRangeOpen}
                    setOpen={setFiledDateRangeOpen}
                  />
                )}
                Filed Date
                <span
                  className={`clear-date ${selectedFilters.dateFiled[0] && selectedFilters.dateFiled[1] ? '' : 'hidden'}`}
                  onClick={() => applyFiltersDate('dateFiled', [])}>
                    Clear
                </span>
              </h4>
              <div className="filter-group-inner">
                <div className="fieldset-date">
                  <label htmlFor="filedDateStart">Start</label>
                  <input
                    type="text"
                    name="filedDateStart"
                    value={selectedFilters.dateFiled && selectedFilters.dateFiled[0] ? selectedFilters.dateFiled[0] : ''}
                    disabled
                  />
                </div>
                <div className="fieldset-date">
                  <label htmlFor="filedDateEnd">End</label>
                  <input
                    type="text"
                    name="filedDateEnd"
                    value={selectedFilters.dateFiled && selectedFilters.dateFiled[1] ? selectedFilters.dateFiled[1] : ''}
                    disabled
                  />
                </div>
              </div>
              
              <h4 className="filter-heading date margin-t-2">
                {setExpiryDateRangeOpen && (
                  <DateRangeSelect
                    id="expiry"
                    onChange={onDateChange}
                    open={expiryDateRangeOpen}
                    setOpen={setExpiryDateRangeOpen}
                  />
                )}
                Expiry Date
                <span
                  className={`clear-date ${selectedFilters.dateExpiry[0] && selectedFilters.dateExpiry[1] ? '' : 'hidden'}`}
                  onClick={() => applyFiltersDate('dateExpiry', [])}>
                    Clear
                </span>
              </h4>
              <div className="filter-group-inner">
                <div className="fieldset-date">
                  <label htmlFor="expiryDateStart">Start</label>
                  <input
                    type="text"
                    name="expiryDateStart"
                    value={selectedFilters.dateExpiry && selectedFilters.dateExpiry[0] ? selectedFilters.dateExpiry[0] : ''}
                    disabled
                  />
                </div>
                <div className="fieldset-date">
                  <label htmlFor="expiryDateEnd">End</label>
                  <input
                    type="text"
                    name="expiryDateEnd"
                    value={selectedFilters.dateExpiry && selectedFilters.dateExpiry[1] ? selectedFilters.dateExpiry[1] : ''}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col filter-group">
              <h4>Patent Type</h4>
              <div className="filter-group-inner">
                {filters?.patentType && filters.patentType.map((filter, idx) => (
                  <div className="fieldset" key={`f-patentType-${idx}`}>
                    <input
                      type="checkbox"
                      id={`patentType-${filter}`}
                      name={`patentType-${filter}`}
                      onChange={() => applyFilters('patentType', filter)}
                      checked={selectedFilters.patentType.includes(filter)}
                    />
                    <label htmlFor={`patentType-${filter}`}>{filter}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="col filter-group">
              <div className="filter-group-sub">
                <h4>Status</h4>
                <div className="filter-group-inner">
                  {filters?.status?.map((filter, idx) => (
                    <div className="fieldset" key={`f-status-${idx}`}>
                      <input
                        type="checkbox"
                        id={`status-${filter}`}
                        name={`status-${filter}`}
                        onChange={() => applyFilters('status', filter)}
                        checked={selectedFilters.status.includes(filter)}
                      />
                      <label htmlFor={`status-${filter}`}>
                        {filter}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="filter-group-sub">
                <h4>FDA Approval</h4>
                <div className="filter-group-inner">
                  {filters?.statusFdaApproval?.map((filter, idx) => (
                    <div className="fieldset" key={`f-statusFdaApproval-${idx}`}>
                      <input
                        type="checkbox"
                        id={`statusFdaApproval-${filter}`}
                        name={`statusFdaApproval-${filter}`}
                        onChange={() => applyFilters('statusFdaApproval', filter)}
                        checked={selectedFilters.statusFdaApproval.includes(filter)}
                      />
                      <label htmlFor={`statusFdaApproval-${filter}`}>
                        {filter}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="filter-group-sub">
                <h4>Orange/Purple Book</h4>
                <div className="filter-group-inner">
                  {filters?.statusOPBook?.map((filter, idx) => (
                    <div className="fieldset" key={`f-statusOPBook-${idx}`}>
                      <input
                        type="checkbox"
                        id={`statusOPBook-${filter}`}
                        name={`statusOPBook-${filter}`}
                        onChange={() => applyFilters('statusOPBook', filter)}
                        checked={selectedFilters.statusOPBook.includes(filter)}
                      />
                      <label htmlFor={`statusOPBook-${filter}`}>
                        {OPBookLabel(filter)}
                        {filter}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="results-count-heading">
          <p>
            {resultsLength(patentDataFiltered)}
          </p>
          
          {cookies && cookies.userHasSubmittedEmail && (
            <a
              className="download-data"
              href="https://www.i-mak.org/wp-content/uploads/2022/09/i-mak-drug-patent-book-database-20220914.csv"
              download
            >
              <IconDownload /> Download full data
            </a>
          )}
        </div>

        <div className="table-wrapper">
          <div className="table-wrapper-inner">
            <table>
              <thead>
                <tr>
                  <th className="sortable drug"
                    onClick={() => setSortBy(['drug', sortBy[1] === 'ASC' ? 'DESC' : 'ASC'])}
                  >
                    <span className="sort drug-sort">
                      <SortableIcon sorted={sortBy[0] === 'drug'} dir={(sortBy[0] === 'drug' && sortBy[1]) || ''} />
                    </span>
                    Drug
                  </th>
                  <th>Assignee</th>
                  <th>Patent/Publication Number</th>

                  <th className="sortable"
                    onClick={() => setSortBy(['dateFile', sortBy[1] === 'ASC' ? 'DESC' : 'ASC'])}
                  >
                    <span className="sort">
                      <SortableIcon sorted={sortBy[0] === 'dateFile'} dir={sortBy[0] === 'dateFile' && sortBy[1] || ''} />
                    </span>
                    Filed
                  </th>
                  {/* <th> */}
                  <th className="sortable"
                    onClick={() => setSortBy(['dateExpiry', sortBy[1] === 'ASC' ? 'DESC' : 'ASC'])}
                  > 
                    <span className="sort">
                      <SortableIcon sorted={sortBy[0] === 'dateExpiry'} dir={sortBy[0] === 'dateExpiry' && sortBy[1] || ''} />
                    </span>
                    Expiry
                  </th>
                  <th>Status</th>
                  <th>Patent Type</th>
                  <th>FDA <br />Approval</th>
                  <th>Orange/<br />Purple Book</th>
                </tr>
              </thead>

              <tbody>
                {cookies && cookies.userHasSubmittedEmail && patentDataFiltered?.map((data, idx) => (
                  <>
                    <tr key={`tr-${idx}`}>
                      <td
                        className={`drug ${expandedRows.includes(idx) && 'expanded'}`}
                        onClick={() => toggleRow(idx)}
                      >
                        <span className="drug-toggle">
                          {expandedRows.includes(idx) ? (
                            <IconChevronUp />
                          ) : (<IconChevronDown />)}
                        </span>
                        {data.drug}
                      </td>
                      <td className="assignee">{data.assignee}</td>
                      <td className="patentNo"><a href={data.urlGooglePatents} target="_blank" rel="noreferrer">{data.patentNo}</a></td>
                      <td className="dateFile">{dateFormatted(data.dateFile)}</td>
                      <td className="dateExpiry">{dateFormatted(data.dateExpiry)}</td>
                      <td className="status">{data.status}</td>
                      <td className="patentType">{data.patentType}</td>
                      <td className="fdaApproval">{data.statusFdaApproval}</td>
                      <td className="statusOPBook">{data.statusOPBook ? 'Yes' : 'No'}</td>
                    </tr>
                    <tr key={`tr-expandable-${idx}`} className={`tr-expandable ${expandedRows.includes(idx) ? 'expanded' : ''}`}>
                      <td colSpan="9">
                        <p><strong className="label">Family:</strong> {data.family} <strong className="label margin-l">Priority Date:</strong> {dateFormatted(data.datePriority)} <strong className="label margin-l">Granted/Publication/Reissued Date:</strong> {dateFormatted(data.datePublication)}</p>
                        <p><strong className="label">Drug Type:</strong> {data.drugType} <strong className="label margin-l">Condition(s) Treated:</strong> {data.conditionsTreated}</p>
                        <p>{data.description}</p>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>

            {!resultsLength(patentDataFiltered) && (
              <div className="no-results">
                <p>No results match your search. Please try again.</p>
                <button
                  className={`btn btn-grey btn-clear ${objectIsEmpty(selectedFilters) && 'hidden'}`}
                  onClick={() => clearFilters()}
                >
                  Clear All Filters
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
