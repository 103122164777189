import { NavLink } from 'react-router-dom';
import './Nav.css';

export default function Nav() {
  return (
    <div className="nav-tabs-container">
      <div className="nav-tabs">
        <NavLink
          to="/"
          className={({ isActive }) => (isActive  ? 'active' : 'inactive')}
        >
          Dataset
        </NavLink>
        <NavLink
          to="analysis"
          className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        >
          Analysis Builder
        </NavLink>
      </div>
    </div>
  )
}
