import './Menu.css';

export default function Menu() {
  return (
    <nav className="nav-primary">
      <div className="nav-primary-container">
        <a href="https://www.i-mak.org" className="nav-logo">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 200 70">
            <g>
              <g>
                <path className="st0" d="M28.7,24.1c-2-2-4-3.9-6.1-5.6C20.8,17,19,15.6,17,14.3c-0.8,0.6-1.5,1.1-2.2,1.8c-0.7,0.7-1.3,1.4-1.8,2.2 c1.3,2,2.7,3.8,4.2,5.5c1.8,2.1,3.6,4.1,5.6,6.1s4,3.9,6.1,5.6c1.8,1.5,3.6,2.9,5.5,4.2c0.8-0.6,1.5-1.1,2.2-1.8s1.3-1.4,1.8-2.2 c-1.3-2-2.7-3.8-4.2-5.5C32.5,28.1,30.6,26,28.7,24.1z"></path>
                <path className="st1" d="M65,58.5L53.3,46.8c-1.5-1.5-3.5-1.9-5.3-1.4l-2.7-2.7c7.8-9.8,7.2-24.2-1.9-33.3l0,0 C33.7-0.4,17.8-0.4,8,9.3C3.3,14,0.7,20.3,0.7,27s2.6,13,7.4,17.7c4.9,4.9,11.3,7.4,17.7,7.4c5.5,0,11.1-1.8,15.7-5.5l2.7,2.7 c-0.5,1.8,0,3.9,1.4,5.3l11.7,11.7c1.1,1.1,2.5,1.6,3.9,1.6c1.4,0,2.8-0.6,3.9-1.6C67.2,64.1,67.2,60.6,65,58.5z M11.9,40.8 C8.2,37.1,6.2,32.2,6.2,27c0-5.3,2-10.2,5.7-13.8c3.7-3.7,8.6-5.7,13.8-5.7c5.3,0,10.2,2,13.8,5.7c7.6,7.6,7.6,20,0,27.7 c-3.7,3.7-8.6,5.7-13.8,5.7C20.5,46.6,15.6,44.5,11.9,40.8z"></path>
              </g>
              <g>
                <circle className="st0" cx="89.2" cy="25.8" r="4.1"></circle>
                <path className="st1" d="M157.5,11.5c0-0.1-0.1-0.1-0.1-0.1c-0.4-0.6-0.9-1.1-1.5-1.4c-0.4-0.2-0.8-0.3-1.3-0.4h-0.1 c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0h-0.1c-0.4,0-0.8,0.1-1.3,0.4c-0.6,0.3-1.2,0.8-1.5,1.4c0,0.1-0.1,0.1-0.1,0.1l-0.1,0.1 c0,0,0,0.1-0.1,0.1l-11.6,24.9c-0.4,0.8-0.4,1.8-0.1,2.7c0.4,0.9,1,1.6,1.8,2c0.8,0.4,1.8,0.4,2.7,0.1c0.9-0.4,1.6-1,2-1.8 l1.6-3.5h13.5l1.6,3.5c0.8,1.8,3,2.6,4.8,1.8c1.8-0.8,2.6-3,1.8-4.8L157.5,11.5z M151,29l3.4-7.2l3.4,7.2H151z"></path>
                <path className="st1" d="M198.4,35.9l-9.2-11.1l9-9c0.7-0.7,1.1-1.6,1.1-2.5s-0.4-1.9-1.1-2.5c-0.7-0.7-1.6-1.1-2.5-1.1 s-1.9,0.4-2.5,1.1l-10.4,10.4v-7.9c0-2-1.6-3.6-3.6-3.6s-3.6,1.6-3.6,3.6v24.9c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6v-6.8 l1.5-1.5l8.8,10.6c0.6,0.8,1.5,1.2,2.5,1.3c1,0.1,1.9-0.2,2.7-0.8C199.5,39.7,199.7,37.4,198.4,35.9z"></path>
                <path className="st1" d="M73.7,9.7c-2,0-3.6,1.6-3.6,3.6v24.9c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6V13.3 C77.3,11.3,75.7,9.7,73.7,9.7z"></path>
                <path className="st1" d="M127.1,10.8l-9.9,9.9l-9.9-9.9c-0.3-0.3-0.6-0.6-1.1-0.7l-0.2-0.1c-0.8-0.3-1.7-0.3-2.5,0l-0.2,0.1 c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.6,0.6-0.7,1.1l-0.1,0.2c-0.1,0.4-0.2,0.8-0.2,1.3v24.9c0,2,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6 V22l6.3,6.3c0.7,0.7,1.6,1.1,2.6,1.1l0,0c1,0,1.9-0.4,2.5-1.1l0.1-0.1l6.2-6.2v16.2c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6V13.3 c0-0.2,0-0.4-0.1-0.6v-0.1c0-0.1,0-0.1,0-0.1l-0.1-0.3c-0.2-0.6-0.5-1-0.8-1.4c-0.3-0.3-0.7-0.6-1.1-0.8h-0.1 c-0.1,0-0.1,0-0.1-0.1H131c-0.1,0-0.1-0.1-0.1-0.1c-0.8-0.3-1.6-0.2-2.3,0.1h-0.1c-0.1,0-0.1,0.1-0.1,0.1l-0.1,0.1 c-0.1,0-0.1,0-0.1,0.1l-0.1,0.1C127.6,10.4,127.4,10.6,127.1,10.8z"></path>
              </g>
            </g>
          </svg>
        </a>

        {/* <div className="menu-primary-container">
          <ul id="menu-primary" className="menu menu-primary"><li id="menu-item-2974" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-2974"><a href="https://www.i-mak.org/mandate/">Our Mandate<span className="submenu-toggle"></span></a>
            <ul className="sub-menu">
              <li id="menu-item-2975" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2975"><a href="https://i-mak.org/mandate/#impact">Our Work<span className="submenu-toggle"></span></a></li>
              <li id="menu-item-3754" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3754"><a href="https://www.i-mak.org/pcm/">Participatory Changemaking<span className="submenu-toggle"></span></a></li>
              <li id="menu-item-2977" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2977"><a href="https://i-mak.org/mandate/#leadership">Leadership Team<span className="submenu-toggle"></span></a></li>
            </ul>
          </li>
            <li id="menu-item-2978" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-2978"><a href="https://www.i-mak.org/health-equity/">Health Equity<span className="submenu-toggle"></span></a>
              <ul className="sub-menu">
                <li id="menu-item-2979" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2979"><a href="https://i-mak.org/health-equity/#system">Medicines System<span className="submenu-toggle"></span></a></li>
                <li id="menu-item-2980" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2980"><a href="https://i-mak.org/health-equity/#pricing">Drug Pricing<span className="submenu-toggle"></span></a></li>
                <li id="menu-item-3477" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3477"><a href="https://www.i-mak.org/patent-reform/">Patent Reform<span className="submenu-toggle"></span></a></li>
              </ul>
            </li>
            <li id="menu-item-3242" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3242"><a href="https://www.i-mak.org/covid/">Covid Response</a></li>
            <li id="menu-item-3201" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3201"><a href="https://www.i-mak.org/press/">Press</a></li>
            <li id="menu-item-3904" className="menu-item-cta menu-item menu-item-type-post_type menu-item-object-page menu-item-3904"><a href="https://www.i-mak.org/donate/">Donate</a></li>
          </ul>
        </div> */}
      </div>
    </nav>
  )
}
