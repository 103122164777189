import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import { IconCalendarDays } from '../Icons';

const DateRangeSelect = ({ id, onChange, open, setOpen }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
      id: id,
    }
  ]);

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  }

  return (
    <>
      <span className="cal-icon" onClick={() => setOpen(!open)}>
        <IconCalendarDays />
      </span>
      {open && (
        <DateRange
          editableDateInputs={true}
          onChange={handleOnChange}
          moveRangeOnFirstSelection={false}
          ranges={state}
        />
      )}
    </>
  )
}

DateRangeSelect.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
};

export default DateRangeSelect;
