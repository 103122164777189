import './Header.css';

export default function Header() {
  return (
    <div className="page">
      <header className="header">
        <div className="container">
          <div className="header-content">
            <h1>The Drug Patent Book</h1>
            <p className="hero-sub">Better Information, Better Decisions.</p>
            <p className="hero-text">We deserve transparency and access to the information about the drug patents impacting Americans and their healthcare. Lack of critical data on patents prevents lawmakers from making informed decisions which can help to reduce prescription drug costs.</p>
          </div>
        </div>
      </header>
      <section className="section-grid">
        <div className="container">
          <div className="grid">
            <a className="grid-item link" href="https://www.i-mak.org/patent-methods/#learn" target="_blank" rel="noreferrer">
              <h3>Learn How</h3>
              <img src="https://www.i-mak.org/wp-content/uploads/2022/07/illo-video.png" alt="" width="128" height="128" />
              <p>Watch a quick tutorial on how to navigate our new database and build your own analyses.</p>
            </a>
            <a className="grid-item link" href="https://www.i-mak.org/patent-methods/" target="_blank" rel="noreferrer">
              <h3>Background &amp; Methods</h3>
              <img src="https://www.i-mak.org/wp-content/uploads/2022/07/illo-conversation.png" alt="" width="128" height="128" />
              <p>Learn why this database was created and our patent search methodologies.</p>
            </a>
            <a className="grid-item link" href="https://www.i-mak.org/2021-top-selling/" target="_blank" rel="noreferrer">
              <h3>Data Summary</h3>
              <img src="https://www.i-mak.org/wp-content/uploads/2022/07/illo-search.png" alt="" width="128" height="128" />
              <p>Get a comprehensive market and patent summary on America's Top Selling Drugs.</p>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
