const padToTwoDigits = (num) => {
  return num.toString().padStart(2, '0');
}

const formatDate = (date) => {
  return [
    date.getFullYear(),
    padToTwoDigits(date.getMonth() + 1),
    padToTwoDigits(date.getDate()),
  ].join('-');
}

const objectIsEmpty = (obj) => {
  return Object.keys(obj).every(function (key) {
    var val = obj[key];

    if ((Array.isArray(val) && val.length === 0) || (val === '')) {
      return true;
    }

    return false;
  });
}

const resultsLength = (data) => {
  if (data && data.length > 1) {
    return data.length + ' results';
  } else if (data && data.length === 1) {
    return data.length + ' result'
  } else {
    return '';
  }
}

// Pivot utils
const pivotTableHasValues = (state) => {
  return (state && Object.keys(state).length && Object.keys(state).length > 0) || false;
}

const addSeparators = function (nStr, thousandsSep, decimalSep) {
  const x = String(nStr).split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? decimalSep + x[1] : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1${thousandsSep}$2`);
  }
  return x1 + x2;
};

const numberFormat = function (opts_in) {
  const defaults = {
    digitsAfterDecimal: 2,
    scaler: 1,
    thousandsSep: ',',
    decimalSep: '.',
    prefix: '',
    suffix: '',
  };
  const opts = Object.assign({}, defaults, opts_in);
  return function (x) {
    if (isNaN(x) || !isFinite(x)) {
      return '';
    }
    const result = addSeparators(
      (opts.scaler * x).toFixed(opts.digitsAfterDecimal),
      opts.thousandsSep,
      opts.decimalSep
    );
    return `${opts.prefix}${result}${opts.suffix}`;
  };
};

const usFmtInt = numberFormat({ digitsAfterDecimal: 0 });
const usFmtPct = numberFormat({
  digitsAfterDecimal: 1,
  scaler: 100,
  suffix: '%',
});

const aggregatorTemplates = {
  count(formatter = usFmtInt) {
    return () =>
      function () {
        return {
          count: 0,
          push() {
            this.count++;
          },
          value() {
            return this.count;
          },
          format: formatter,
        };
      };
  },

  fractionOf(wrapped, type = 'total', formatter = usFmtPct) {
    return (...x) =>
      function (data, rowKey, colKey) {
        return {
          selector: { total: [[], []], row: [rowKey, []], col: [[], colKey] }[
            type
          ],
          inner: wrapped(...Array.from(x || []))(data, rowKey, colKey),
          push(record) {
            this.inner.push(record);
          },
          format: formatter,
          value() {
            return (
              this.inner.value() /
              data
                .getAggregator(...Array.from(this.selector || []))
                .inner.value()
            );
          },
          numInputs: wrapped(...Array.from(x || []))().numInputs,
        };
      };
  },
}

const customAggregators = (tpl => ({
  Count: tpl.count(usFmtInt),
  '% of Total': tpl.fractionOf(tpl.count(), 'total', usFmtPct),
  '% of Rows': tpl.fractionOf(tpl.count(), 'row', usFmtPct),
  '% of Columns': tpl.fractionOf(tpl.count(), 'col', usFmtPct),
}))(aggregatorTemplates);

export {
  formatDate,
  objectIsEmpty,
  resultsLength,
  pivotTableHasValues,
  customAggregators,
};
