import React from 'react';
import { IconSort, IconSortUp, IconSortDown } from '../Icons';

const SortableIcon = (sort) => {
    if (sort.sorted) {
        if (sort.dir === 'ASC') {
            return (<IconSortUp />)
        } else {
            return (<IconSortDown />)
        }
    } else {
        return (
            <IconSort />
        );
    }
}

export {
    SortableIcon
};
