import {
  Routes,
  Route,
} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import './App.css';
import Menu from './components/Menu';
import Header from './components/Header';
import Nav from './components/Nav';
import Main from './components/Main';
import Pivot from './components/Pivot';
import React from 'react';

function App() {
  const [cookies, setCookie] = useCookies();

  return (
    <div className="App">
      {/* <Router> */}
        <Menu />
        <Header />
        {cookies && cookies.userHasSubmittedEmail && (
          <Nav />
        )}
        <Routes>
          <Route path="analysis" element={<Pivot />} />
          <Route path="/" element={<Main />} />
        </Routes>
      {/* </Router> */}
    </div>
  );
}

export default App;
