import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import { pivotTableHasValues, customAggregators } from '../utils';
import './Pivot.css';
import MailchimpFormContainer from './MailchimpFormContainer';


// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);
const data = require('../data.json');
const dataValues = data.data.map(({ conditionsTreated, drug, drugType, patentType, status, statusFdaApproval, statusOPBook }) => (
  [conditionsTreated, drug, drugType, patentType, status, statusFdaApproval, statusOPBook]
));
const dataPivot = [
  ['Condition(s) Treated', 'Drug', 'Drug Type', 'Patent Type', 'Status', 'FDA Approval', 'Orange/Purple Book'],
  ...dataValues,
]

export default function Pivot() {
  const [state, setState] = useState({
    aggregatorName: 'Count'
  });
  const [cookies, setCookie] = useCookies();

  if (data) {
    return (
      <div className={`Main pivot-table-container ${!cookies.userHasSubmittedEmail && 'subscribe-overlay'}`}>
        {!cookies.userHasSubmittedEmail && (
          <MailchimpFormContainer />
        )}
        <>
          <div className="container">
            <div className="filters-heading">
              <button
                className={`btn btn-grey btn-clear ${pivotTableHasValues(state) === false && 'hidden'}`}
                onClick={() => setState()}
              >
                Clear Data
              </button>
            </div>
          </div>
          <div className="container container-scroll-x">
            <PivotTableUI
              data={dataPivot}
              onChange={s => setState(s)}
              renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
              aggregators={customAggregators}
              {...state}
            />
          </div>
        </>
      </div>
    );
  }
  else {
    return ('Loading');
  }
}
